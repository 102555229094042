<template>
  <div class="createWorkTeacher">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
    >
      <div class="createWorkTeacher-body">
        <div class="item" v-for="(item, index) in listData" :key="index">
          <div class="item-t">
            <img :src="item.picture" alt="" />
          </div>
          <div class="item-b">
            <div class="name">
              <div class="name-l">{{ item.name }}</div>
              <div class="name-r"><img src="./img/medal.png" alt="" /></div>
            </div>
            <div class="other">
              <div class="other-l">
                <div class="label">{{ item.label }}</div>
                <div class="service">
                  共服务{{ returnData(item.serveCount) }}次
                </div>
              </div>
              <div class="other-r">
                <div class="other-rl" @click.stop="toAlink(item)">
                  <div class="img"><img src="./img/tel.png" alt="" /></div>
                </div>
                <div class="other-rr" @click.stop="toWechat(item)">
                  <div class="img"><img src="./img/wechat.png" alt="" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-list>
    <div class="wechatBox" v-if="isDialog" @click="closeDialog">
      <div class="img" @click.stop="showImg">
        <img :src="imgUrl" alt="" class="code" />
        <img @click.stop="closeDialog" src="./img/close.png" class="close" />
      </div>
    </div>
    <!-- 在线咨询弹框 -->
    <v-dialog
      confirmButtonColor="#5E5AFF"
      v-model="isDialog_phone"
      @confirm="confirm_phone"
    >
      <p class="dialogTitle">提示</p>
      <p class="dialogText">确认拨打电话：{{ phoneNum }}吗？</p>
    </v-dialog>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getTeacherListUrl } from "./api.js";

import { gloabalCount } from "@/utils/common";
export default {
  name: "createWorkTalent",
  data() {
    return {
      phoneNum: "",
      isDialog: false,
      isDialog_phone: false,
      finishedText: "没有更多了",
      finished: false,
      imgUrl: "",
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      listData: [],
    };
  },
  mounted() {
    gloabalCount("", 36, 1);
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  methods: {
    confirm_phone() {
      window.location.href = `tel://${this.phoneNum}`;
    },
    showImg() {},
    closeDialog() {
      this.isDialog = false;
    },
    toWechat(item) {
      this.imgUrl = item.wechatQrcode;
      this.isDialog = true;
    },
    toAlink(item) {
      // window.location.href = `tel://${item.mobile}`;
      this.isDialog_phone = true;
      this.phoneNum = item.mobile;
    },
    returnData(data) {
      if (!data) {
        return "0";
      }
      if (data >= 10000) {
        return Math.round(data / 10000) + "万";
      } else {
        return data;
      }
    },
    getTalentList() {
      let params = {
        tenantId: this.tenantId,
        houseId: this.roomId || this.communityId || undefined,
        curPage: this.curPage,
        pageSize: this.pageSize,
      };
      this.$axios
        .get(`${getTeacherListUrl}`, { params: params })
        .then((res) => {
          console.log(res, 333);
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.activityPicture) {
                item.activityPicture = this.$handleImg(
                  400,
                  300,
                  item.activityPicture
                );
              }
              this.listData.push(item);
            });
          } else {
          }
        });
    },
    onLoad() {
      this.getTalentList();
    },
    toDetail(item) {
      //   let params = {
      //     activityId: item.activityId,
      //   };
      //   wx.miniProgram.navigateTo({
      //     url: `/xubPages/activityDetail/index?params=${JSON.stringify(params)}`,
      //   });
      this.$router.push({
        name: "createWorkTalentDetail",
        query: { id: item.id },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.createWorkTeacher {
  .dialogTitle {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    padding: 50px 0 26px 0;
  }
  .dialogText {
    text-align: center;
    font-size: 30px;
    color: rgba(0, 0, 0, 0.5);
    padding-bottom: 40px;
  }
  .wechatBox {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
    .img {
      width: 400px;
      position: relative;
      height: 400px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .code {
        width: 100%;
        height: 100%;
      }
      .close {
        width: 76px;
        height: 76px;
        position: absolute;
        bottom: -120px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .createWorkTeacher-body {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .item {
    width: 330px;
    flex-shrink: 0;
    height: 452px;
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    border-radius: 16px;
    margin-bottom: 58px;
    .item-t {
      width: 100%;
      height: 294px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 16px 16px 0 0;
      }
    }
    .item-b {
      padding: 16px 20px;
      .name {
        display: flex;
        align-items: center;
        margin: 2px 0 12px;
        .name-l {
          font-size: 32px;
          font-weight: 600;
          color: #333333;
          line-height: 1;
          margin-right: 10px;
        }
        .name-r {
          width: 26px;
          height: 38px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .other {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .other-l {
          .label {
            font-size: 28px;
            font-weight: 400;
            color: #ffb92d;
            line-height: 28px;
            margin-bottom: 6px;
          }
          .service {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.25);
            line-height: 28px;
          }
        }
        .other-r {
          display: flex;
          align-items: center;
          .other-rl {
            text-align: center;
            margin-right: 12px;
          }
          .img {
            width: 36px;
            height: 36px;
            margin: 0 auto 0px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .txt {
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 22px;
          }
        }
      }
    }
  }
}
</style>
